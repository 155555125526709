import React, { useContext, useEffect, useState } from 'react';
import { EmailOutlined, PrintOutlined, Replay } from '@material-ui/icons';
import Navlink from '../../components/displayValues/NavLink';
import { UserContext } from '../../components/UserContext';
import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';
import CurrencyText from '../../components/displayValues/CurrencyText';
import StaticContentDatatable from '../../components/StaticContentDatatable';
import Typography from '@material-ui/core/Typography';
import PhoneNumber from '../../components/displayValues/PhoneNumber';
import { useHistory } from 'react-router-dom';
import GoodbricksLegacyAPIClient from '../../restClients/GoodbricksLegacyAPIClient';
import { useSnackbar } from 'notistack';
import { USER_ROLES } from '../../Constants';

export default function CustomersByYear(props) {
  const { authenticatedUser, userRole } = useContext(UserContext);
  const history = useHistory();
  const [initRowData, setInitRowData] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const isInvalidEmail = (email) => {
    return !email || email.length <= 0 || email.includes('@caretapps.com') || email.includes('goodbricks');
  };

  useEffect(() => {
    ElasticSearchAPIClient.getAllCustomersWithTransactionInYear(authenticatedUser, '2024').then((response) => {
      setInitRowData(response);
    });
  }, []);

  const bulkActions = [
    {
      id: 'bulk-send-receipt',
      visible: (userRole >= USER_ROLES.FRONT_DESK_ADMIN),
      type: 'link',
      displayName: 'Send Selected Receipts',
      icon: <EmailOutlined />,
      doAction: (ids) => {
        ids.forEach((id) => {
          GoodbricksLegacyAPIClient.sendYearEndReceiptToDonor(authenticatedUser, id, '2024').then((response) => {
            enqueueSnackbar('Sent tax receipt to : ' + response.customerEmail, {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
          });
        });
      },
    },
    {
      id: 'bulk-print-receipt',
      type: 'link',
      displayName: 'Print Selected Receipts',
      icon: <PrintOutlined />,
      doAction: (ids) => {
        history.push('/tax-receipts/year-end/preview', {
          custIds: ids,
        });
      },
    },
  ];
  const singleRowActions = [
    {
      id: 'email-receipt',
      visible: (userRole >= USER_ROLES.FRONT_DESK_ADMIN),
      displayName: 'Email Receipt',
      // alertMessage: 'Sending receipt...',
      doAction: (rowData) => {
        if (isInvalidEmail(rowData.email)) {
          enqueueSnackbar("Couldn't send email to " + rowData.email + '. Invalid email address.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
        } else {
          enqueueSnackbar('Sending tax receipt to : ' + rowData.email, {
            variant: 'default',
            anchorOrigin: {
              autoHideDuration: 1500,
              vertical: 'top',
              horizontal: 'center',
            },
          });
          GoodbricksLegacyAPIClient.sendYearEndReceiptToDonor(authenticatedUser, rowData.id, '2024').then(
            (response) => {
              enqueueSnackbar('Sent tax receipt to : ' + rowData.email, {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              });
            },
          );
        }
      },
      icon: <EmailOutlined disabled fontSize="small" color="secondary" />,
    },
    {
      id: 'print-receipt',
      visible: (userRole >= USER_ROLES.FRONT_DESK_ADMIN),
      displayName: 'Print Receipt',
      doAction: (rowData) => {
        history.push('/tax-receipts/year-end/preview', {
          custIds: [rowData.id],
        });
      },
      icon: <PrintOutlined fontSize="small" color="secondary" style={{ marginTop: '0.3rem' }} />,
    },
  ];
  const downloadSourceFields = [
    'name',
    'email',
    'phone',
    'addressStreet',
    'addressApt',
    'addressCity',
    'addressState',
    'addressZipCode',
    'paymentMethod',
    'totalTransactionAmount',
    'totalTransactionCount',
    'lastTransactionDate',
    'dateCreated',
  ];
  const columns = [
    {
      id: 'name',
      field: 'name',
      disablePadding: true,
      label: 'Name',
      cellContent: (rowdata) => {
        return <span style={{ textTransform: 'capitalize' }}>{rowdata['name']}</span>;
      },
    },
    {
      id: 'email',
      field: 'email',
      disablePadding: true,
      label: 'Email',
    },
    {
      id: 'phone',
      field: 'phone',
      disablePadding: true,
      label: 'Phone',
      cellContent: (rowdata) => {
        return <PhoneNumber data={rowdata['phone']} />;
      },
    },
    {
      id: 'donationTotal',
      field: 'donationTotal',
      align: 'right',
      disablePadding: false,
      label: 'Amount',
      cellContent: (rowdata) => {
        return <CurrencyText amount={rowdata['donationTotal']} />;
      },
    },
    {
      id: 'donationCount',
      field: 'donationCount',
      disablePadding: false,
      label: '# of Donations',
    },
  ];
  return (
    <React.Fragment>
      {userRole >= USER_ROLES.VIEW_ONLY && (initRowData ? (
        <StaticContentDatatable
          bulkActions={bulkActions}
          globalActions={[]}
          singleRowActions={singleRowActions}
          columns={columns}
          initRowData={initRowData}
          // onMoreInfoClick={onMoreInfoClick}
        />
      ) : (
        <Typography paragraph={true} variant="body1" gutterBottom>
          {' '}
          Loading Data ... <br /> This may take a few minutes ...
        </Typography>
      ))}
    </React.Fragment>
  );
}
